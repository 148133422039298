<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <div class='lineGrey'></div>
    <div class='contractBlock'>
      <div v-for="(item,id) in list" :key="id" class='contractOne'>
        <div class='rowOne'>
          <div class='rentTime'>起租时间：{{item.START_DATE}}</div>
          <div class='rentStatus'>{{item.CONTRACT_STATUS}}</div>
        </div>
        <div class='rowTwo'>
          <div class='item_title'>客户名称：</div>
          <div class='item_text'>{{item.ENT_NAME}}</div>
        </div>
        <div class='rowTwo'>
          <div class='item_title'>合同编号：</div>
          <div class='item_text'>{{item.CONTRACT_NUMBER}}</div>
        </div>
        <div class='rowTwo'>
          <div class='item_title'>车辆数量：</div>
          <div class='item_text'>{{item.CARNUM}}</div>
        </div>
        <div class='rowOne'>
          <div class='rentButton' @click='rentalPlan(item)'>查看租金计划</div>
          <div class='carDetail' @click='iscarDetail(item)'>
            <span style='line-height: 12px;'>车辆详情</span> 
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="carDetailShow" :lock-scroll='false'>
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="closeTop">
            <img class="closeImg" @click='closeCarDetail' src="../../../assets/img/system-closeb@2x.png"/>
            <span class='blockTitle'>车辆详情</span>
          </div>
          <div style='height: 500px;overflow-y: scroll;'>
            <div class='carMain' v-for='(value,index) in carDataList'>
              <div class='carLine'>
                <div class='carLeft'>单车合同号：</div>
                <div class='carRight'>{{value.CONTRACT_PLAN_NUMBER}}</div>
              </div>
              <div class='carLine'>
                <div class='carLeft'>车型：</div>
                <div class='carRight'>{{value.CARKIND}}</div>
              </div>
              <div class='carLine'>
                <div class='carLeft'>车架号：</div>
                <div class='carRight'>{{value.FRAME_NUMBER}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
  import { querytype } from '@/server'
  import { comconlist, comcardetail } from '@/api/enterprise'
  import { Toast } from 'vant';
  import Loading from "@/components/Loading";
  import { setCookie, getCookie, nfmoney, domainUrl, hidewxOption } from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      openId:getCookie('openId'),
      carDetailShow: false,
      list:[],
      carDataList: [],
      cardId: this.$route.query.cardId || ''
    };
  },
  mounted(){
    
  },
  created(){
    //隐藏分享
    hidewxOption()
    this.getList()
  },
  methods: {
    getList(){
      const params = {
        card_id: this.cardId,
        access_token: this.$store.state.accessToken
      }
      comconlist(params).then(res=>{
        if(res.status=='200'){
          this.list = res.data
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    rentalPlan(item){
      querytype({
        card_id: this.cardId,
        function_type: 'button',
        button_name: "查看租金计划",
        pre_menu: "公户_租金计划"
      });
      this.$router.push({ path: '/enterprise/contractList/electronicRent' ,query: { contractNumber: item.CONTRACT_NUMBER }});
    },
    iscarDetail(item){
      this.carDetailShow = true
      comcardetail({
        contract_number: item.CONTRACT_NUMBER
      }).then(res=>{
        if(res.status=='200'){
          this.carDataList = res.data
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    closeCarDetail(){
      this.carDetailShow = false
    }
  },
};
</script>
<style scoped>
.wrap{
  height: 100vh;
  background: #F5F5F5;
}
.contractBlock{
  background: #F5F5F5;
  padding-bottom: 10px;
}
.lineGrey{
  width: 100%;
  height: 10px;
  background-color: #F5F5F5;
}
.contractOne{
  margin: 0 10px 10px 10px;
  border-radius:4px;
  background-color: #ffffff;
}
.rowOne{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px 20px;
}
.rentTime{
  color: #666666;
  font-size: 12px;
}
.rentStatus{
  font-size: 13px;
  color: #3662EC;
}
.rowTwo{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 6px 20px;
}
.item_title{
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  width: 100px;
}
.item_text{
  color: #333333;
  font-size: 13px;
}
.rentButton{
  color: #0256FF;
  border: 1px solid #0256FF;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  border-radius: 22px;
  width: 104px;
}
.carDetail{
  color: #666666;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* 遮罩样式 */
.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  /* height: 20rem; */
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow-y: scroll;
  position: relative;
}
.closeTop{
  border-bottom: 1px solid #EEEEEE;
  
}
.closeImg{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.blockTitle{
  color: #1A1A1A;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  height: 56px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.carMain{
  margin-left: 20px;
  padding: 17px 20px 7px 0;
  border-bottom: 1px solid #EEEEEE;
}
.carLine{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
.carLeft{
  color: #666666;
  font-size: 15px;
}
.carRight{
  color: #666666;
  font-size: 15px;
}
</style>
<style>
#app{
  background: #F8F8F8 !important;
}
</style>